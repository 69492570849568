
      @use 'styles/vars' as *;
      @use 'styles/breakpoints' as bp;
      @use 'styles/typography' as type;
      @use 'styles/colours' as col;
      @use 'styles/utilities' as util;
      @use 'styles/animations' as animate;
    
.sortable-th {
  padding-right: padding(1.5);
}

.sort-arrow {
  color: col.$primary;
  cursor: pointer;
  opacity: 0.7;
  position: absolute;
  right: spacing(1);
  top: spacing(1);
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out !important;

  @include util.dark-mode() {
    color: col.$light;
  }

  .header-solid & {
    color: col.$light;
  }

  &::after {
    content: '▾';
  }

  &:hover {
    opacity: 1;
  }

  &.active {
    opacity: 1;
  }
}

.dir-asc {
  transform: rotate(180deg);
}

.filter-input {
  margin-bottom: 0;

  input {
    height: auto;
    padding: padding(0.2);
  }
}

.hide-header {
  &::before {
    display: none !important;
  }
}
