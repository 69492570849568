
      @use 'styles/vars' as *;
      @use 'styles/breakpoints' as bp;
      @use 'styles/typography' as type;
      @use 'styles/colours' as col;
      @use 'styles/utilities' as util;
      @use 'styles/animations' as animate;
    
.video-embed {
  margin: 0 auto;
  padding: 0 padding(2);
  align-items: center;
  text-align: center;
  width: 70%;
  height: auto;
  iframe {
    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;
  }
}
