
      @use 'styles/vars' as *;
      @use 'styles/breakpoints' as bp;
      @use 'styles/typography' as type;
      @use 'styles/colours' as col;
      @use 'styles/utilities' as util;
      @use 'styles/animations' as animate;
    
.grid {
  column-gap: spacing(4);
  display: grid;
  grid-auto-rows: auto;
  margin-bottom: margin(2);
  row-gap: spacing(3);

  & > * {
    // Allows individual overflow-x: scroll to work (e.g. in tables)
    min-width: 0;
  }
}

.grid-1-up {
  @include util.mq(bp.$medium) {
    grid-template-columns: 1fr;
  }
}

.grid-2-up {
  @include util.mq(bp.$medium-small) {
    grid-template-columns: 1fr 1fr;
  }

  &.grid-2-1 {
    @include util.mq(bp.$medium-small) {
      grid-template-columns: 2fr 1fr;
    }
  }

  &.grid-1-2 {
    @include util.mq(bp.$medium-small) {
      grid-template-columns: 1fr 2fr;
    }
  }

  &.grid-1-3 {
    @include util.mq(bp.$medium-small) {
      grid-template-columns: 1fr 3fr;
    }
  }

  &.grid-3-1 {
    @include util.mq(bp.$medium-small) {
      grid-template-columns: 3fr 1fr;
    }
  }

  &.grid-4-1 {
    @include util.mq(bp.$medium-small) {
      grid-template-columns: 4fr 1fr;
    }
  }

  &.grid-1-4 {
    @include util.mq(bp.$medium-small) {
      grid-template-columns: 1fr 4fr;
    }
  }
}

.grid-3-up {
  @include util.mq(bp.$medium-small) {
    grid-template-columns: 1fr 1fr;

    > *:last-child:nth-child(2n - 1) {
      grid-column: span 2;
    }
  }

  @include util.mq(bp.$medium) {
    grid-template-columns: 1fr 1fr 1fr;

    > *:last-child:nth-child(2n - 1) {
      grid-column: span 1;
    }
  }
}

.grid-4-up {
  @include util.mq(bp.$medium-small) {
    grid-template-columns: 1fr 1fr;
  }

  @include util.mq(bp.$medium) {
    grid-template-columns: 1fr 1fr 1fr 1fr;

    > *:last-child:nth-child(4n - 3) {
      grid-column: span 4;
    }
  }
}
