
      @use 'styles/vars' as *;
      @use 'styles/breakpoints' as bp;
      @use 'styles/typography' as type;
      @use 'styles/colours' as col;
      @use 'styles/utilities' as util;
      @use 'styles/animations' as animate;
    
.hero {
  background-color: col.$secondary;
  height: fit-content;
  padding: padding(2);
  padding-top: padding(10);
  position: relative;
  width: 100%;
  margin-top: -(margin(8.5));
  @include util.mq(bp.$medium-small) {
    padding: padding(15) padding(2) padding(10);
  }

  @include util.mq(bp.$medium-large) {
    margin-top: -(margin(12));
  }

  .content {
    align-items: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    gap: spacing(6);
    margin: margin(1);
    position: relative;
    z-index: 10;

    &.with-copy {
      gap: spacing(2);
      padding-top: padding(4);
      text-align: center;
    }

    > h1 {
      @include type.heading-large();

      font-weight: bold;
    }

    > p {
      @include type.body-text();

      font-weight: bold;

      @include util.mq(bp.$medium-small) {
        width: bp.$medium-small;
      }
    }
  }

  &.overlay {
    color: col.$light;

    .image,
    .video {
      filter: opacity(0.3);
    }
  }

  .image,
  .video {
    inset: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    z-index: 1;
  }

  &.has-video {
    .image {
      display: block;

      @include util.mq(bp.$medium-small) {
        display: none;
      }
    }
  }

  .video {
    display: none;

    @include util.mq(bp.$medium-small) {
      display: block;
    }
  }
}
