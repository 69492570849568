
      @use 'styles/vars' as *;
      @use 'styles/breakpoints' as bp;
      @use 'styles/typography' as type;
      @use 'styles/colours' as col;
      @use 'styles/utilities' as util;
      @use 'styles/animations' as animate;
    
.two-slot-rich-text-image {
  display: grid;
  grid-template-columns: 1fr;
  gap: spacing(2);

  @include util.mq(bp.$medium) {
    grid-template-columns: 1fr 1fr;
  }

  .left-slot,
  .right-slot {
    position: relative;
    min-height: 500px;
  }

  &.has-caption .left-slot,
  &.has-caption .right-slot {
    min-height: 520px;
  }

  .left-slot .content {
    padding-left: 0;

    @include util.mq(bp.$medium) {
      padding-left: padding(2);
    }
  }

  .right-slot .content {
    padding-top: padding(3);

    @include util.mq(bp.$medium) {
      padding-top: padding(1);
    }
  }

  .content {
    padding-top: padding(1);
    padding-bottom: padding(1);
  }

  .image-wrapper {
    position: relative;
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
  }

  .image {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%; // Fixed height for images
  }

  .image-attribution {
    position: absolute;
    bottom: 8px;
    right: 8px;
    background-color: col.$grey-100;
    color: col.$grey-700;
    padding: spacing(0.3) spacing(0.5);
    border-radius: 4px;
    font-size: 12px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }

  .caption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-style: italic;
    color: col.$grey-700;
  }

  .image {
    border-radius: $border-radius;
    overflow: hidden;
  }
}
