
      @use 'styles/vars' as *;
      @use 'styles/breakpoints' as bp;
      @use 'styles/typography' as type;
      @use 'styles/colours' as col;
      @use 'styles/utilities' as util;
      @use 'styles/animations' as animate;
    
.button-group {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: spacing(1);
  justify-content: flex-start;
  list-style: none;
  margin: 0;
  padding: 0;
}

.collapsed {
  gap: 0;

  .grouped-button {
    border-left: 1px dotted col.$light;
    border-radius: 0;
    margin: 0;

    @include util.dark-mode {
      border-left: 1px dotted col.$dark;
    }

    &:first-of-type {
      border-left: 0;
      border-radius: $border-radius-lg 0 0 $border-radius-lg;
    }

    &:last-of-type {
      border-radius: 0 $border-radius-lg $border-radius-lg 0;
    }
  }
}

.vertical {
  flex-direction: column;

  &.collapsed {
    .grouped-button {
      border-left: 0;
      border-top: 1px dotted col.$light;

      @include util.dark-mode {
        border-top: 1px dotted col.$dark;
      }

      &:first-of-type {
        border-radius: $border-radius-lg $border-radius-lg 0 0;
        border-top: 0;
      }

      &:last-of-type {
        border-radius: 0 0 $border-radius-lg $border-radius-lg;
      }
    }
  }
}

.invalid {
  align-items: center;
  border: 1px dashed col.$danger;
  display: flex;

  span {
    background-color: col.$danger;
    color: col.$light;
    display: block;
    font-weight: bold;
    margin: 1px;
    margin-right: margin(1);
    padding: padding(1);
  }
}
