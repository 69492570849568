
      @use 'styles/vars' as *;
      @use 'styles/breakpoints' as bp;
      @use 'styles/typography' as type;
      @use 'styles/colours' as col;
      @use 'styles/utilities' as util;
      @use 'styles/animations' as animate;
    
.plain-text {
  margin: 0 auto;
  padding: padding(2);

  &.centered {
    align-items: center;
    text-align: center;
  }

  h2 {
    @include type.heading-small();

    font-weight: 900;
    margin-bottom: margin(2);
  }

  p {
    @include type.body-text();
  }
}
