
      @use 'styles/vars' as *;
      @use 'styles/breakpoints' as bp;
      @use 'styles/typography' as type;
      @use 'styles/colours' as col;
      @use 'styles/utilities' as util;
      @use 'styles/animations' as animate;
    
.content-card {
  @include util.card();

  background-color: col.$light;
  container-type: inline-size;
  grid-template-rows: 180px auto;
  text-align: center;

  @include util.mq(bp.$medium-small) {
    text-align: unset;
  }

  &.featured {
    box-shadow: none;
  }

  &.no-image {
    background-color: col.$tertiary;
    grid-template-rows: auto auto;
  }

  .link {
    display: block;
    height: 100%;
    position: relative;

    &-button {
      @include util.button-primary();
      padding: padding(1) padding(4);
    }
  }

  &.landscape {
    position: relative;
    min-height: 250px;

    @include util.mq(bp.$medium-small) {
      grid-template-columns: 50% 50%;
      grid-template-rows: 1fr;

      .bottom {
        place-content: center;

        .cta {
          margin-top: 0;
        }
      }

      &.swapped {
        .top {
          order: 2;
        }

        .bottom {
          order: 1;
        }
      }
    }
  }
}

.metadata {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: margin(1.5);
}

.author,
.author-no-image {
  font-size: type.$small-text-size;
  margin-top: margin(0.5);
  font-style: italic;
}

.date {
  position: absolute;
  bottom: spacing(1);
  right: spacing(1);
  z-index: 10;
}

.tag-news,
.tag-report {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  text-transform: capitalize;
  font-weight: bold;

  border-radius: 0;
}

.tag-news {
  background-color: col.$primary;
  color: darken(col.$primary, 50%);
}

.tag-report {
  background-color: col.$tertiary;
  color: darken(col.$tertiary, 50%);
}

@container (min-width: 250px) {
  .top .title {
    @include type.heading-large();
  }
}

.top {
  position: relative;
  width: 100%;
  .title {
    @include type.heading-small();

    color: col.$body-text;
    font-weight: bold;
    letter-spacing: 0;
  }

  .bg-takeover &,
  .no-image & {
    padding: padding(2);
    padding-bottom: padding(0.5);
    padding-left: padding(4);
    margin-bottom: margin(2);

    .title {
      line-height: 1.4;
    }
  }
}

.bottom {
  background-color: col.$light;
  display: grid;
  flex-direction: column;
  padding: padding(4);
  width: 100%;
  grid-template-rows: auto 1fr auto;

  .no-image & {
    padding-top: 0;
  }

  .no-image &,
  .bg-takeover & {
    background-color: transparent;
  }

  .title {
    @include type.large-text();
    color: col.$body-text;
    font-weight: bold;
    line-height: 1.5;
  }

  .cta {
    display: flex;
    justify-content: center;
    margin-top: auto;
    gap: spacing(2);

    &.cta-link {
      @include util.mq(bp.$medium-small) {
        justify-content: flex-start;
      }
    }
  }
}

.image {
  object-fit: cover;
}

.content {
  line-height: 1.5;
}

.clickable {
  cursor: pointer;
}
