
      @use 'styles/vars' as *;
      @use 'styles/breakpoints' as bp;
      @use 'styles/typography' as type;
      @use 'styles/colours' as col;
      @use 'styles/utilities' as util;
      @use 'styles/animations' as animate;
    
.list-item {
  &.is-active {
    background: col.$action;

    .inner {
      color: col.$body-text-inverse;
    }
  }

  &.is-clickable {
    .inner {
      appearance: none;
      background-color: transparent;
      cursor: pointer;

      &:focus-visible {
        box-shadow: 0 0 0 2px col.$keyboard-focus;
        outline: 0;
      }
    }

    &:hover {
      background: col.$action-hover;

      .inner {
        color: col.$body-text-inverse;
      }
    }
  }

  &.is-loading {
    @include util.skeleton();
  }

  &.in-ordered-list,
  &.in-unordered-list {
    .inner {
      margin-bottom: margin(1);
      padding: 0 padding(1);
    }
  }

  &.in-arrow-list {
    align-items: center;
    display: grid;
    grid-template-columns: 15px auto;
  }
}

.arrow {
  fill: col.$primary;
  height: 11px;
  transform: rotate(90deg);
  width: 11px;
}

.inner {
  color: col.$body-text;
  display: block;
  font-size: type.$body-text-size;
  padding: padding(1) 0;
  text-align: left;
  width: 100%;
}
