
      @use 'styles/vars' as *;
      @use 'styles/breakpoints' as bp;
      @use 'styles/typography' as type;
      @use 'styles/colours' as col;
      @use 'styles/utilities' as util;
      @use 'styles/animations' as animate;
    
.table {
  background-color: col.$grey-warm;
  border-collapse: collapse;
  border-spacing: 0;
  color: col.$grey-900;
  margin-bottom: margin(1);
  width: 100%;
}

.striped tr:nth-child(odd) td {
  background-color: col.$grey-200;
}
