
      @use 'styles/vars' as *;
      @use 'styles/breakpoints' as bp;
      @use 'styles/typography' as type;
      @use 'styles/colours' as col;
      @use 'styles/utilities' as util;
      @use 'styles/animations' as animate;
    
.rich-text-and-form {
  display: grid;
  gap: spacing(2);
  grid-template-rows: fit-content 1fr;

  @include util.mq(bp.$medium-small) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: unset;
  }

  &.swap-sides {
    .form {
      order: 1;
    }

    .rich-text {
      order: 2;
    }
  }
}
