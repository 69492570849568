
      @use 'styles/vars' as *;
      @use 'styles/breakpoints' as bp;
      @use 'styles/typography' as type;
      @use 'styles/colours' as col;
      @use 'styles/utilities' as util;
      @use 'styles/animations' as animate;
    
.date-box {
  background-color: rgba(col.$grey-100, 0.7);
  border: 1px solid col.$grey-500;
  display: flex;
  flex-direction: column;
  gap: spacing(0.3);
  align-items: center;
  border-radius: 3px;
  padding: padding(0.5) padding(1);
  width: fit-content;
  backdrop-filter: blur(4px);
}

.day {
  font-size: 20px;
  font-family: 'Arial Black', sans-serif;
  font-weight: 900;
  color: col.$dark;
  letter-spacing: 0.05em;
}

.month {
  font-size: 18px;
  font-weight: bold;
  color: col.$grey-700;
}

.year {
  font-size: 14px;
  font-weight: 400;
  color: col.$grey-700;
  letter-spacing: 0.05em;
}
