
      @use 'styles/vars' as *;
      @use 'styles/breakpoints' as bp;
      @use 'styles/typography' as type;
      @use 'styles/colours' as col;
      @use 'styles/utilities' as util;
      @use 'styles/animations' as animate;
    
.rich-text-and-image {
  display: grid;
  gap: spacing(1);
  grid-template-rows: 1fr 1fr;

  @include util.mq(bp.$medium-small) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: unset;
  }

  &.swap-sides {
    .image {
      order: 1;
    }

    .rich-text {
      order: 2;
    }
  }
}

.image {
  border-radius: $border-radius;
  overflow: hidden;
  position: relative;

  img {
    object-fit: cover;
  }
}
