
      @use 'styles/vars' as *;
      @use 'styles/breakpoints' as bp;
      @use 'styles/typography' as type;
      @use 'styles/colours' as col;
      @use 'styles/utilities' as util;
      @use 'styles/animations' as animate;
    
.thead {
  display: table-header-group;
}

.solid th {
  background-color: col.$primary-darker;
  color: col.$light;
}
