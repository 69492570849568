
      @use 'styles/vars' as *;
      @use 'styles/breakpoints' as bp;
      @use 'styles/typography' as type;
      @use 'styles/colours' as col;
      @use 'styles/utilities' as util;
      @use 'styles/animations' as animate;
    
.table-row {
  background-color: transparent;
  box-shadow: none !important;
  display: table-row;
  margin-bottom: 0;
  padding: 0;

  &.selectable {
    cursor: pointer;

    &:hover,
    &:focus {
      background: rgba(col.$dark, 0.08);
    }
  }

  &.active {
    background: lighten(col.$action-focus-bg, 25%);
  }
}
