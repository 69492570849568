
      @use 'styles/vars' as *;
      @use 'styles/breakpoints' as bp;
      @use 'styles/typography' as type;
      @use 'styles/colours' as col;
      @use 'styles/utilities' as util;
      @use 'styles/animations' as animate;
    
.table-cell {
  display: table-cell;
  padding: padding();
  position: relative;
}

.th {
  font-weight: bold;
}
