
      @use 'styles/vars' as *;
      @use 'styles/breakpoints' as bp;
      @use 'styles/typography' as type;
      @use 'styles/colours' as col;
      @use 'styles/utilities' as util;
      @use 'styles/animations' as animate;
    
.raw-html {
  margin: 0 auto;
  overflow-x: auto;

  &.centered {
    align-items: center;
    text-align: center;
  }

  a {
    text-decoration: underline;
  }

  table {
    background-color: col.$grey-warm;
    border-collapse: collapse;
    border-spacing: 0;
    color: col.$grey-900;
    margin-bottom: margin(1);
    width: 100%;
    font-size: type.$small-text-size;

    @include util.mq(bp.$medium-small) {
      font-size: type.$body-text-size;
    }
  }

  table tr {
    background-color: col.$grey-200;
  }

  table tr:nth-child(even) {
    background-color: #ffffff;
  }

  table thead tr {
    background-color: col.$primary-darker;
    color: col.$light;
  }

  table th {
    font-weight: bold;
  }

  table td,
  table th {
    padding: 1rem 2rem;
    overflow-wrap: break-word;
  }
}
