
      @use 'styles/vars' as *;
      @use 'styles/breakpoints' as bp;
      @use 'styles/typography' as type;
      @use 'styles/colours' as col;
      @use 'styles/utilities' as util;
      @use 'styles/animations' as animate;
    
.full-width-image {
  border-radius: $border-radius;
  height: auto;
  min-height: 350px;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-bottom: margin(4);

  .image {
    object-fit: cover;
  }
}
